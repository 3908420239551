import App from "./App";
import CustomWidget from "./custom/custom-widget.js";

const customTag = 'pewniaczki-bonus';

window.customElements.whenDefined(customTag)
    .then(() =>{
        Object.assign(window.customElements.get(customTag).prototype, CustomWidget);
    })
    .catch((e) => {
        console.error(e);
    });

export default App;
