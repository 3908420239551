<template>
  <div
    id="pewniaczki-bonus-widget"
    v-responsive="{
      small: (el) => el.width <= 500,
      medium: (el) => el.width > 500 && el.width <= 800,
      normal: (el) => el.width > 800,
    }"
  >
    <div class="matches" v-for="(bonus, b) in data.bonuses" :key="b">
      <div class="bonus">
        <div class="header">
          <div v-if="bonus.label.length > 0" :class="'label label-'+bonus.labelClass">{{ bonus.label }}</div>
          {{ bonus.title }}
        </div>
        <div class="content">
          <div class="image" :style="bonus.color ? 'background-color: ' + bonus.color : ''">
            <a :href="bonus.out.length > 0 ? bonus.out : '/'" target="_blank" class="btn" @click="cta(bonus.name)">
              <img :src=bonus.image />
            </a>
          </div>
          <div class="details">
            <div class="h">Nasze bonusy</div>
            <ul>
              <li v-for="(element, e) in bonus.list" :key="e" v-html="element"></li>
            </ul>
          </div>
          <div class="link">

            <div v-if="bonus.code.length > 0" class="code" @click="copy(`code-`+bonus.name)">
              <span>Kod:</span> <input type="text" :value="bonus.code" :id="`code-`+bonus.name" />
            </div>

            <a v-if="bonus.out.length > 0" :href="bonus.out" target="_blank" class="btn" @click="cta(bonus.name)">Odbierz bonus</a>
          </div>
        </div>
        <div class="footer">
          <button @click="expand(bonus)" :class="isExpaned(bonus) ? 'expanded' : 'folded'">Zobacz szczegóły promocji</button>
        </div>
        <div class="expand" v-html="bonus.details.replace()" v-if="isExpaned(bonus)">
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ResponsiveDirective } from "vue-responsive-components";

const API_URL = "https://pewniaczki.pl/wp-json/api/bonus";

export default {
  name: "pewniaczki-bonus-widget",
  props: {
    affId: Number,
  },
  directives: {
    responsive: ResponsiveDirective,
  },
  data() {
    return {
      data: [],
      expanded: [],
      ready: false,
      slim: false,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      const query = [];
      for (const prop in this.$props) {
        if (this[prop])
          query.push(
            prop
              .split(/(?=[A-Z])/)
              .join("_")
              .toLowerCase() +
              "=" +
              this[prop]
          );
      }

      axios
        .get(API_URL + "?" + query.join("&"))
        .then((response) => (this.data = response.data))
        .catch(() => {});

      this.resize();
      window.addEventListener("resize", this.resize.bind(this));
    },

    resize() {
      const element = this.$root.$options.customElement;
      const width = element ? element.offsetWidth : window.innerWidth;
      this.slim = width < 400;
    },
    expand(match) {
      match.expanded = !match.expanded;
      this.$forceUpdate();
    },
    isExpaned(match) {
      return match.expanded;
    },
    cta(name) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'click-cta',
        'name': name
      });
    },
    copy(e) {


      try {
        let codeToCopy = document.querySelector('pewniaczki-bonus-widget').shadowRoot.querySelector('#'+e)
 
        codeToCopy.select()
        var successful = document.execCommand('copy')
        if(successful) alert('Twój kod został skopiowany. Użyj go na stronie bukmachera')
        window.getSelection().removeAllRanges() 
      } catch (err) {
        alert('Nie można skopiować kodu');
      }

    }
  },
};
</script>

<style lang="scss">
@import "./assets/reset.scss";
@import "./assets/widget.scss";
</style>
